<template>
  <div>
    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      variant="gradient-warning"
      @click="
        () => {
          filterbycode = '';
          isActiveFilter = true;
        }
      "
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Agendamento">
                <b-form-input type="number" v-model="appointment_id" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Empresas">
                <v-select
                  v-model="companieSelected"
                  :loading="loadingCompanies"
                  @search="getCompanies"
                  :options="companies"
                  :clearable="false"
                  searchable
                  multiple
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Projetos">
                <v-select
                  v-model="projectSelected"
                  :options="projects"
                  :clearable="false"
                  @search="getProjects"
                  :loading="loadingProjects"
                  multiple
                  searchable
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Professor">
                <v-select
                  v-model="teachersSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="teachers"
                  autocomplete="off"
                  @search="getTeachers"
                  searchable
                  :loading="loadingTeacher"
                  :clearable="false"
                  multiple
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Situação">
                <v-select
                  v-model="statusSelected"
                  class="select-pending"
                  :options="status"
                  autocomplete="off"
                  :clearable="false"
                  multiple
                />
              </b-form-group>
            </b-col>

            <b-col cols="9">
              <b-button @click="filter" variant="gradient-info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col cols="3">
              <b-button @click="filterClear" variant="gradient-primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>

    <viewcard--c title="Materiais Pendentes" :btnew="btnew">
      <b-modal
        v-model="modalAppointmentRequests"
        title="Solicitar Agendamento"
        hide-footer
        @hide="onHideTransfer"
      >
        <div class="row">
          <div class="col-6">
            <label><b>Tem uma data de previsão?</b></label>
            <b-form-input type="date" v-model="recordAppointmentRequest.date" />
          </div>
          <div class="col-6">
            <label><b>Tem um horário de previsão?</b></label>
            <b-form-input
              type="time"
              min="00:00"
              max="23:59"
              step="900"
              v-model="recordAppointmentRequest.hour"
            />
          </div>
          <div class="col-6">
            <label><b>Informe a duração necessária</b></label>
            <flat-pickr
              v-model="recordAppointmentRequest.duration"
              placeholder="Duração"
              class="form-control"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true,
              }"
            />
          </div>

          <div class="col-12">
            <label><b>Localidade</b></label>
            <v-select
              v-model="studiosGroupOptionsSelected"
              :options="studiosGroupOptions"
              autocomplete="off"
              :clearable="false"
            >
            </v-select>
          </div>

          <div class="col-12">
            <small class="text-muted mt-5">
              Data e hora informada não GARANTIDO! Pois depende de
              disponibilidade de estúdio.</small
            >
          </div>
          <div class="col-12">
            <br />
            <b-button
              variant="gradient-info"
              @click="onClickCreateAppointmentRequest"
            >
              Confirmar
            </b-button>
          </div>
        </div>
      </b-modal>

      <view--c
        permission="permission.audiovisual.recording.media.pending.view"
        :busy="isloading"
      >
        <Table
          :fields="fields"
          :list="
            !isloading && list[currentePage] ? list[currentePage].itens : []
          "
          @orderBy="getRecordsOrderBy"
          border="full"
          responsive
          style="font-size: 10px"
          class="text-nowrap mb-0"
        >
          <template #company_name="data">
            <div
              :data-text="data.item.company_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.company_name || "--" }}
            </div>
          </template>

          <template #project_name="data">
            <div
              :data-text="data.item.project_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.project_name || "--" }}
            </div>
          </template>

          <template #appointment_id="data">
            {{data.item.appointment_id || "--"}}
          </template>

          <template #teacher_name="data">
            <div
              :data-text="data.item.teacher_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.teacher_name || "--" }}
            </div>
          </template>

          <template #event_course="data">
            <div
              :data-text="data.item.event_course || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.event_course || '--' }}
            </div>
          </template>
         
          <template #limitDate="data">
            {{
              data.item.limitDate
                ? data.item.limitDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")
                : null
            }}
          </template>

          <template #completed="data">
            <div
              class="vselect-pending"
              :id="'switch-' + data.item.id"
              style="width: 45px"
            >
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="data.item.completed"
                switch
                :disabled="data.item.status > 1"
                @change="changeState(data.item)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon
                    :icon="data.item.status > 1 ? 'XIcon' : 'AlertCircleIcon'"
                  />
                </span>
              </b-form-checkbox>
              <b-tooltip
                v-if="data.item.status > 1"
                :target="'switch-' + data.item.id"
                placement="right"
                triggers="hover"
              >
                {{ statusCode(data.item.status) }}
              </b-tooltip>
            </div>
          </template>

          <template #actions="data">
            <div class="d-flex">
              <feather-icon
                @click="confirmDelete(data.item.id)"
                v-if="btDel"
                icon="TrashIcon"
                class="mx-75"
                size="16"
              />
              <feather-icon
                @click="viewPending(data.item.id)"
                icon="EyeIcon"
                class="mx-75"
                size="16"
              />
              <feather-icon
                @click="editPending(data.item.id)"
                v-if="btEdit"
                icon="EditIcon"
                class="mx-75"
                size="16"
              />
              <div
                v-if="
                  data.item.appointmentRequests &&
                  data.item.appointmentRequests.length > 0
                "
              >
                <!-- CHECK -->
                <feather-icon
                  v-if="data.item.appointmentRequests[0].appointment_id > 0"
                  :id="`btn-solicitacao-like-${data.item.id}`"
                  icon="CheckCircleIcon"
                  class="mx-75"
                  size="16"
                  @click="
                    $router.push({
                      name: 'pedagogic-calendar',
                      query: {
                        q: data.item.appointmentRequests[0].appointment_id,
                      },
                    })
                  "
                />
                <feather-icon
                  :id="`btn-solicitacao-deslike-${data.item.id}`"
                  icon="ThumbsUpIcon"
                  color="warning"
                  class="mx-75"
                  size="16"
                  v-else
                />

                <!-- CHECK -->
                <b-popover
                  :target="`btn-solicitacao-like-${data.item.id}`"
                  placement="right"
                  triggers="hover"
                >
                  <div style="font-size: 11px">
                    <p style="max-width: 300px" class="m-0 text-truncate">
                      Agendamento

                      <b
                        class="cursor-pointer"
                        @click="
                          $router.push({
                            name: 'pedagogic-calendar',
                            query: {
                              q: data.item.appointmentRequests[0]
                                .appointment_id,
                            },
                          })
                        "
                      >
                        #{{ data.item.appointmentRequests[0].appointment_id }}
                      </b>
                    </p>
                  </div>
                </b-popover>

                <!-- DESLIKE -->
                <b-popover
                  :target="`btn-solicitacao-deslike-${data.item.id}`"
                  placement="right"
                  triggers="hover"
                >
                  <div style="font-size: 11px">
                    <p style="max-width: 300px" class="m-0 text-truncate">
                      Aguardando Agendamento
                    </p>
                  </div>
                </b-popover>
              </div>
              <feather-icon
                @click="onClickAppointmentRequests(data.item)"
                :id="`btn-gerar-solicitacao${data.item.id}`"
                v-else-if="btAptRequests"
                icon="AnchorIcon"
                class="mx-75"
                size="16"
              />
              <b-popover
                :target="`btn-gerar-solicitacao${data.item.id}`"
                placement="right"
                triggers="hover"
              >
                <div style="font-size: 11px">
                  <p style="max-width: 300px" class="m-0 text-truncate">
                    Solicitar Agendamento
                  </p>
                </div>
              </b-popover>
            </div>
          </template>
        </Table>

        <b-pagination
          v-model="currentePage"
          @change="getLoadMore"
          :total-rows="rows"
          :per-page="size"
          v-if="rows > 1"
          first-number
          last-number
          align="center"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </view--c>
    </viewcard--c>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import _recordingPendingService from "@/services/audiovisual/recording-pending";
import _appointmentRequests from "@/services/audiovisual/appointment-requests";
import _studioGroupService from "@/services/studios-group-service";

import _companyService from "@/services/company-service";
import _projectsService from "@/services/projects-service";
import _teachersService from "@/services/teachers-service";
import { BTooltip, VBPopover, BPopover } from "bootstrap-vue";

export default {
  components: {
    Table,
    BTooltip,
    BPopover,
  },
  directives: {
    "b-popover": VBPopover,
  },
  filters: {
    addZero: (value) => {
      if (!value) return "--";
      value = value + "";
      if (value.length > 1) return value;
      return `0${value}`;
    },
  },
  data() {
    return {
      isActiveFilter: false,
      btnew: {
        permission: "permission.audiovisual.recording.media.pending.create",
        to: "/audio-visual/recoding-pending/0",
      },
      modalAppointmentRequests: false,
      recordingMediaSelected: null,
      recordAppointmentRequest: {
        date: "",
        hour: "",
        duration: "01:00:00",
      },
      studiosGroupOptions: [],
      studiosGroupOptionsSelected: null,
      isloading: false,
      loadingCompanies: false,
      loadingProjects: false,
      loadingTeacher: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        {
          key: "company_name",
          label: "Empresa",
          orderBy: { type: "back" },
        },
        {
          key: "project_name",
          label: "Projeto",
          orderBy: { type: "back" },
        },
        {
          key: "event_course",
          label: "Evento/curso",
        },
        {
          key: "teacher_name",
          label: "Professor",
          orderBy: { type: "back" },
        },
        { key: "limitDate", label: "Prazo", orderBy: { type: "back" } },
        {
          key: "appointment_id",
          label: "Agend.",
          tooltip: "Agendamento",
          orderBy: { type: "back" },
        },
        { key: "completed", label: "Situação", orderBy: { type: "back" } },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: "",
        ascOrDes: true,
        orderByKey: null,
      },
      companies: [],
      companieSelected: [],
      projects: [],
      projectSelected: [],
      teachers: [],
      teachersSelected: [],
      status: [],
      statusSelected: [{ label: "pendente", value: 0 }], //inicia somente com pendentes
      btAptRequests: false,
      appointment_id: null
    };
  },
  created() {
    this.btEdit = this.$utils.isPermited(
      "permission.audiovisual.recording.media.pending.edit"
    );
    this.btDel = this.$utils.isPermited(
      "permission.audiovisual.recording.media.pending.delete"
    );

    this.btAptRequests = this.$utils.isPermited(
      "permission.appointment.requests.create"
    );
    this.getRecords(this.currentePage);
    this.getStatus();
  },
  methods: {
    onHideTransfer(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    getStatus() {
      _recordingPendingService.status().then((res) => {
        res.forEach((element) => {
          this.status.push({ value: element.item2, label: element.item1 });
        });
      });
    },
    async onClickAppointmentRequests(_item) {
      this.isloading = true;
      const groups = await _studioGroupService.show(1, { size: 200 });
      this.isloading = false;

      if (groups && groups.content) {
        this.studiosGroupOptions = groups.content.map((m) => {
          const _place = m.place ? m.place.name : "";

          return {
            label: m.name + " - " + _place,
            value: m.id,
          };
        });
      }

      this.recordAppointmentRequest.date = this.$utils.getDateNow();
      this.recordingMediaSelected = _item;
      this.modalAppointmentRequests = true;
    },

    onClickCreateAppointmentRequest() {
      this.isloading = true;
      const _payload = {
        data: {
          recommended_date:
            this.recordAppointmentRequest.date &&
            this.recordAppointmentRequest.hour
              ? `${this.recordAppointmentRequest.date}T${this.recordAppointmentRequest.hour}:00`
              : null,
          recording_media_pending_id: this.recordingMediaSelected.id,
          recommended_duration: this.recordAppointmentRequest.duration,
          studios_group_id: this.studiosGroupOptionsSelected
            ? this.studiosGroupOptionsSelected.value
            : null,
        },
      };

      _appointmentRequests
        .create(_payload)
        .then((res) => {
          this.modalAppointmentRequests = false;
          this.$utils.toast(
            "Notificação",
            "Solicitação de agendamento enviado."
          );
          window.location.reload();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      const companies =
        this.companieSelected.length > 0
          ? this.companieSelected.map((m) => m.value)
          : null;
      const teachers =
        this.teachersSelected.length > 0
          ? this.teachersSelected.map((m) => m.value)
          : null;
      const projects =
        this.projectSelected.length > 0
          ? this.projectSelected.map((m) => m.value)
          : null;
      const completelist =
        this.statusSelected.length > 0
          ? this.statusSelected.map((m) => m.value)
          : null;
      const appointment_id =
        this.appointment_id
          ? this.appointment_id
          : null;
      _recordingPendingService
        .show(
          _page,
          teachers,
          companies,
          projects,
          completelist,
          appointment_id,
          this.orderByParams.orderByKey,
          this.orderByParams.ascOrDes
        )
        .then((res) => {
          this.isActiveFilter = false;
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getSubjects(files) {
      let subjects = [];
      files.forEach((f) => {
        subjects.push(f.subject);
      });
      return subjects;
    },
    totalSubs(files) {
      return files.length;
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
      this.isActiveFilter = false;
    },
    filterClear() {
      this.projectSelected = [];
      this.companieSelected = [];
      this.teachersSelected = [];
      this.statusSelected = [];
      this.appointment_id = null;
      this.list = [{ page: 0, itens: [] }];
      this.getRecords(this.currentePage);
    },
    editPending(id, _) {
      this.$router.push({
        path: `/audio-visual/recoding-pending/${id}`,
      });
    },
    getCompanies(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingCompanies) {
            this.loadingCompanies = true;
            _companyService
              .autoComplete(_search)
              .then((res) => {
                this.companies = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingCompanies = false));
          }
        }, 500);
      }
    },
    getProjects(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingProjects) {
            this.loadingProjects = true;
            _projectsService
              .autoComplete(_search)
              .then((res) => {
                this.projects = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingProjects = false));
          }
        }, 500);
      }
    },
    getTeachers(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.loadingTeacher) {
            this.loadingTeacher = true;
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                this.teachers = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.loadingTeacher = false));
          }
        }, 500);
      }
    },

    viewPending(id) {
      this.$router.push({
        path: `/audio-visual/recoding-pending/view/${id}`,
      });
    },
    confirmDelete(id) {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir(id);
        }
      });
    },
    excluir(id) {
      this.loading = true;
      _recordingPendingService
        .delete(id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.filter();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    changeState(item) {
      this.$swal({
        title: "Confirmar Ação",
        text: "Deseja continuar com a troca de estado da pendência?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, continuar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // APLICAR AÇÃO VIA API AQUI
          const payload = {
            data: {
              completed: item.completed,
              id: item.id,
            },
          };

          this.loading = true;
          _recordingPendingService
            .changeCompleted(payload)
            .then(() => {
              this.$utils.toast("Notificação", "Alterado com sucesso.");
              this.filter();
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.loading = false))
            .finally(() => (this.filter()));
        } else {
          item.completed = !item.completed;
        }
      });
    },
    statusCode(id) {
      let result = "";
      this.status.forEach((s) => {
        if (s.value == id) {
          result = s.label;
        }
      });
      return result;
    },
  },
};
</script>
<style>
.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}
.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}
.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}

.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}

.vselect-pending .custom-switch .custom-control-label::before {
  background-color: var(--warning);
}

.switch-icon-right {
  color: #fff;
}
</style>
